import { Select } from "@/shared/ui/Form";
import { useGetFaqTagList } from "@op/entities";
import classNames from "classnames";
import { t } from "i18next";
import { Control, Controller, FieldErrors } from "react-hook-form";

interface IProps {
  name: string;
  control: Control<any>;
  errors: FieldErrors;
  disabled?: boolean;
}

export const SelectFaqTagController = ({
  name,
  errors,
  control,
  disabled,
}: IProps) => {
  const { data: faqTagsList } = useGetFaqTagList({
    is_enabled: true,
  });

  return (
    <Controller
      disabled={disabled}
      name={name}
      control={control}
      rules={{ required: t("ChooseTag") }}
      render={({ field: { value, onChange } }) => (
        <Select
          disabled={disabled}
          isInvalid={!!errors[name]}
          className="editor-header__rubric"
          value={value}
          setValue={(newValue, e?: React.SyntheticEvent) => {
            e?.preventDefault();
            onChange(newValue);
          }}
          items={faqTagsList?.map((tag) => ({
            value: tag.id,
            label: tag.name,
          }))}
          searchFunction={({ label, search }) =>
            label.toLowerCase().includes(search)
          }
          searchPlaceholder="Искать рубрику"
          button={(selectedItem) => (
            <div
              className={classNames(
                "btn btn-outline black scale-up lighten-up size-s",
                !!errors[name] && "red"
              )}
            >
              <i className="btn__text select__btn-text">
                {selectedItem || "Выбрать рубрику"}
              </i>
              <span className="icon icon-cursor-down select__btn-icon" />
            </div>
          )}
          option={(item: string) => (
            <div onClick={(e) => e.preventDefault()}>
              <em className="dropdown__text select__text">{item}</em>
            </div>
          )}
        />
      )}
    />
  );
};
