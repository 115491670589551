import { observer } from "mobx-react-lite";
import React from "react";

import UserStore from "../../app/providers/MobxStore/model/UserStore";
import Author from "../Authors/Author";
import HeaderMenu from "./HeaderMenu";

function Header() {
  const { author: userAuthor, data: userData, isAuth } = UserStore;

  if (!isAuth || !userData) return null;

  return (
    <header>
      <div className="header">
        <div className="wrap">
          <div className="header__row">
            <div className="header__item"></div>
            <div className="header__item"></div>
            <div className="header__item">
              {/* <button
                  className="btn btn-fond scale-up lighten-up size-s black header__btn-add desktop-only                  "
                  onClick={() => toggleCreatorModalOpen(true)}
                >
                  <span className="icon icon-plus btn__icon" />
                  <i className="btn__text"> {t("Create")}</i>
                </button> */}
              <div className="header__user">
                <Author
                  initials={
                    userAuthor?.initials || userData?.username.slice(0, 2)
                  }
                  avatar={userAuthor?.avatar || ""}
                  className="header__user-pic"
                />
                <HeaderMenu />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default observer(Header);
