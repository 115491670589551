import React from "react";

interface ICardArticleThumbnailProps {
  thumbailUrl: string;
}

const CardArticleThumbnail = ({ thumbailUrl }: ICardArticleThumbnailProps) => {
  const encodedUrl = thumbailUrl ? encodeURI(thumbailUrl) : "";

  return (
    <div
      className="profile-article__thumbnail"
      style={{ backgroundImage: `url("${encodedUrl}")` }}
    />
  );
};

export default CardArticleThumbnail;
