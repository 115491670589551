import TagStore from "@/app/providers/MobxStore/model/TagStore";
import * as TagController from "@/controllers/tag-controller";
import { ConfirmCloseModal, Modal } from "@/features/Modal";
import { requiredValidateWhiteSpaces } from "@/shared/lib/utils/requiredValidateWhiteSpaces";
import { Input, Radio } from "@/shared/ui/Form";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const defaultValues: Omit<TagResponse, "tag_id"> = {
  // tag_id: number,
  display_order: 0,
  is_enabled: true,
  tag: "",
  tagslug: "",
  is_main: false,
  seo_description: "",
  seo_title: "",
};

interface IModalProps extends IModalOpen {
  data: Nullable<TagResponse>;
  onSave?: () => void;
}

type TFormFields = Omit<TagResponse, "tag_id">;

const JournalTagsModal = ({ open, onClose, data, onSave }: IModalProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: data || defaultValues,
    mode: "onSubmit",
  });

  const handleSave = async (formData: TFormFields) => {
    setLoading(true);

    const lastTagId = Object.values(TagStore.data).reduce(
      (maxId, tag) => (maxId = Math.max(tag.tag_id, maxId)),
      0
    ); // todo: tag id should come from server

    const response = data
      ? await TagController.editTag({ tag_id: data.tag_id, ...formData })
      : await TagController.addTag({ tag_id: lastTagId + 1, ...formData });

    if (response) {
      onSave && onSave();
      onClose();
      reset(defaultValues);
    } else {
      // todo: show error
    }
    setLoading(false);
  };

  const handleClose = () => {
    if (Object.keys(dirtyFields).length > 0) {
      setConfirmModalOpen(true);
    } else {
      onClose();
    }
  };

  useEffect(() => {
    if (!open) {
      setConfirmModalOpen(false);
      reset(data || defaultValues);
    }
  }, [open, data, reset]);

  useEffect(() => {
    reset(data || defaultValues);
  }, [data, reset]);

  useEffect(() => {
    reset(data || defaultValues);
  }, [data, reset]);

  return (
    <Modal open={open} onClose={handleClose} className="profile-modal">
      <>
        <form
          className="profile-modal__holder"
          onSubmit={handleSubmit(handleSave)}
        >
          <div className="profile-modal__header">
            {data ? (
              <span className="profile-modal__header-title">
                {t("TagEdit")}
              </span>
            ) : (
              <>
                <span className="profile-modal__header-title">
                  {t("TagAdd")}
                </span>
                <p className="profile-modal__header-text">
                  {t("TagAddDescription")}
                </p>
              </>
            )}
          </div>
          <div className="form profile-modal__form">
            <div className="form__block">
              <span className="label">{t("Name")}</span>
              <Input
                {...register("tag", {
                  ...requiredValidateWhiteSpaces(t("PasteName")),
                })}
                isInvalid={!!errors.tag}
                errorText={errors.tag?.message}
              />
            </div>
            <div className="form__block">
              <span className="label">{t("Identifier")}</span>
              <Input
                {...register("tagslug", {
                  ...requiredValidateWhiteSpaces(t("PasteIdentifier")),
                  pattern: {
                    value: /^([a-zA-Z0-9_-]+)$/,
                    message: t("OnlyLatin"),
                  },
                })}
                isInvalid={!!errors.tagslug}
                errorText={errors.tagslug?.message}
              />
            </div>
            <div className="form__block">
              <span className="label">{t("TagSEOTitle")}</span>
              <Input
                {...register("seo_title", {
                  ...requiredValidateWhiteSpaces(t("PasteTitle")),
                })}
                isInvalid={!!errors.seo_title}
                errorText={errors.seo_title?.message}
              />
            </div>
            <div className="form__block">
              <span className="label">{t("TagSEODescription")}</span>
              <Input
                {...register("seo_description", {
                  ...requiredValidateWhiteSpaces(t("PasteSEODescription")),
                })}
                isInvalid={!!errors.seo_description}
                errorText={errors.seo_description?.message}
              />
            </div>
            <div className="form__block profile-modal__radio-block">
              <Controller
                name="is_enabled"
                control={control}
                render={({ field }) => (
                  <Radio
                    value={!!field.value}
                    onChange={field.onChange}
                    label={t("TagShowOnHome")}
                  />
                )}
              />
            </div>
            <div className="form__block profile-modal__radio-block">
              <Controller
                name="is_main"
                control={control}
                render={({ field }) => (
                  <Radio
                    value={!!field.value}
                    onChange={field.onChange}
                    label={t("TagMain")}
                  />
                )}
              />
            </div>

            <div className="form__block">
              <span className="label">{t("Order")}</span>
              <Input
                type="number"
                {...register("display_order")}
                isInvalid={!!errors.display_order}
                errorText={errors.display_order?.message}
              />
            </div>
            <button
              className={classNames(
                "btn btn-fond scale-up lighten-up btn-full blue",
                Object.keys(errors).length > 0 && "btn-disabled"
              )}
              type="submit"
            >
              {data ? (
                <i className="btn__text">{loading ? t("Saving") : t("Save")}</i>
              ) : (
                <i className="btn__text">
                  {loading ? t("Saving") : t("TagAdd")}
                </i>
              )}
            </button>
          </div>
        </form>
        <ConfirmCloseModal
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          onConfirm={onClose}
        />
      </>
    </Modal>
  );
};

export default JournalTagsModal;
