import React from "react";
import { Outlet } from "react-router-dom";

import Header from "./Header/Header";

const MainContainer = () => {
  return (
    <>
      <Header />
      <main className="main main-admin">
        <Outlet />
      </main>
    </>
  );
};

export default MainContainer;
