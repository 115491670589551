import { UserStore } from "@/app/providers/MobxStore";
import { ConfirmCloseModal, Modal } from "@/features/Modal";
import { notify } from "@/shared/lib/utils/notify";
import { requiredValidateWhiteSpaces } from "@/shared/lib/utils/requiredValidateWhiteSpaces";
import { Input, Textarea } from "@/shared/ui/Form";
import {
  ISeoInfoItem,
  useAddSeoInfoMutation,
  useEditSeoInfoMutation,
} from "@op/entities";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface IModalProps extends IModalOpen {
  data: Nullable<ISeoInfoItem>;
  onSave?: () => void;
}

export const defaultValues: Omit<ISeoInfoItem, "id"> = {
  title: "",
  description: "",
  index: "",
  header: "",
};

type TFormFields = Omit<ISeoInfoItem, "id">;

const SeoInfoModal = ({ open, onClose, data, onSave }: IModalProps) => {
  const { t } = useTranslation();
  const [addSeoInfo, { isLoading: isAddSeoInfoLoading }] =
    useAddSeoInfoMutation();
  const [editSeoInfo, { isLoading: isEditSeoInfoLoading }] =
    useEditSeoInfoMutation();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: data || defaultValues,
    mode: "onSubmit",
  });

  const handleSave = async (formData: TFormFields) => {
    const response = data
      ? await editSeoInfo({
          ...formData,
          id: data.id,
          token: UserStore.token ?? "",
        })
      : await addSeoInfo({
          ...formData,
          token: UserStore.token ?? "",
        });

    if ("error" in response) {
      console.error(response.error);
      notify(
        (response.error as FetchBaseQueryError)?.data ?? t("AnErrorOcurred")
      );
      return;
    }

    if ("data" in response) {
      onSave && onSave();
      onClose();
      reset(defaultValues);
    }
  };

  const handleClose = () => {
    if (Object.keys(dirtyFields).length > 0) {
      setConfirmModalOpen(true);
    } else {
      onClose();
    }
  };

  useEffect(() => {
    if (!open) {
      setConfirmModalOpen(false);
      reset(data || defaultValues);
    }
  }, [open, data, reset]);

  useEffect(() => {
    reset(data || defaultValues);
  }, [data, reset]);

  return (
    <Modal open={open} onClose={handleClose} className="profile-modal">
      <>
        <form
          className="profile-modal__holder"
          onSubmit={handleSubmit(handleSave)}
        >
          <div className="profile-modal__header">
            {data ? (
              <>
                <span className="profile-modal__header-title">
                  {t("SeoInfoEdit")}
                </span>
              </>
            ) : (
              <>
                <span className="profile-modal__header-title">
                  {t("AddSeoInfo")}
                </span>
                <p className="profile-modal__header-text">
                  {t("SeoInfoAddDescription")}
                </p>
              </>
            )}
          </div>
          <div className="form profile-modal__form">
            <div className="form__block">
              <span className="label">{t("SeoInfoIndex")}*</span>
              <Input
                {...register("index", {
                  required: t("PasteSeoInfoIndex"),
                  validate: {
                    ...requiredValidateWhiteSpaces(t("PasteSeoInfoIndex"))
                      .validate,
                    checkSlashes: (value: string) =>
                      (value.startsWith("/") && value.endsWith("/")) ||
                      t("InvalidSeoInfoIndex"),
                  },
                })}
                placeholder={"/"}
                isInvalid={!!errors.index}
                errorText={errors.index?.message}
                disabled={!!data}
              />
            </div>
            <div className="form__block">
              <span className="label">{t("SeoInfoTitle")}*</span>
              <Input
                {...register("title", {
                  ...requiredValidateWhiteSpaces(t("PasteSeoInfoTitle")),
                })}
                isInvalid={!!errors.title}
                errorText={errors.title?.message}
              />
            </div>
            <div className="form__block">
              <span className="label">{t("SeoInfoDescription")}</span>
              <Input
                {...register("description")}
                isInvalid={!!errors.description}
                errorText={errors.description?.message}
              />
            </div>
            <div className="form__block">
              <span className="label">{t("SeoInfoHeader")}</span>
              <Textarea rows={5} {...register("header")} />
            </div>

            <button className="btn btn-fond scale-up lighten-up btn-full blue">
              {data ? (
                <i className="btn__text">
                  {isEditSeoInfoLoading || isAddSeoInfoLoading
                    ? t("Saving")
                    : t("Save")}
                </i>
              ) : (
                <i className="btn__text">
                  {isEditSeoInfoLoading || isAddSeoInfoLoading
                    ? t("Saving")
                    : t("AddSeoInfo")}
                </i>
              )}
            </button>
          </div>
        </form>
        <ConfirmCloseModal
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          onConfirm={onClose}
        />
      </>
    </Modal>
  );
};

export default SeoInfoModal;
