import { Input } from "@/shared/ui/Form";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import UserStore from "../../../app/providers/MobxStore/model/UserStore";
import { ROUTES } from "../../../app/providers/Router/model/consts/routerConsts";

export function Login() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { isAuth, login } = UserStore;
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginRequest>({
    defaultValues: {
      username: "",
      password: "",
    },
    mode: "onBlur",
  });

  const onSubmit = async (data: LoginRequest) => {
    setLoading(true);
    await login(data);
    setLoading(false);
  };

  useEffect(() => {
    if (isAuth) navigate(ROUTES.HOME.path);
  }, [isAuth, navigate]);

  if (isAuth) return null;

  return (
    <div className="main-columns login-page">
      <div className="main-columns__center">
        <div className="wrap">
          <div className="login">
            <div className="login__title-block">
              <strong className="login__title">{t("Enter")}</strong>
              <span className="login__logo" />
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="form login__form"
            >
              <div className="form__block">
                <label className="label">{t("Login")}</label>
                <Input
                  {...register("username", { required: t("PasteFirstName") })}
                  errorText={errors.username?.message}
                />
              </div>
              <div className="form__block">
                <label className="label">{t("Password")}</label>
                <Input
                  {...register("password", { required: t("PastePassword") })}
                  type="password"
                  errorText={errors.password?.message}
                />
              </div>
              <button
                className={classNames(
                  "btn btn-fond scale-up lighten-up btn-full blue",
                  loading && "btn-disabled"
                )}
                type="submit"
              >
                <i className="btn__text">
                  {loading ? t("Loading") : t("Enter2")}
                </i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
