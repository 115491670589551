import { $api, rtkApi } from '@op/api';
import { AxiosResponse } from 'axios';

import { API_PROMO_URL } from '../../Env';
import {
  ISeoInfoAddRequest,
  ISeoInfoDeleteRequest,
  ISeoInfoEditRequest,
  ISeoInfoItem,
  ISeoInfoItemRequest,
  ISeoInfoListResponse,
  ISeoInfoMutationResponse,
} from '../model/types/seoInfo';

const requestListOptions = () => ({
  url: `${API_PROMO_URL}/seo-info`,
});

const requestItemOptions = (params: ISeoInfoItemRequest) => ({
  url: `${API_PROMO_URL}/seo-info/by-url`,
  body: params,
  method: 'post',
});

const requestMutateOptions = ({ token, ...params }: ISeoInfoAddRequest | ISeoInfoEditRequest | ISeoInfoDeleteRequest) => ({
  url: `${API_PROMO_URL}/seo-info/${params.id ?? ''}`,
  body: params,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const api = rtkApi.injectEndpoints({
  endpoints: (build) => ({
    getSeoInfo: build.query<ISeoInfoListResponse, void>({
      query: requestListOptions,
    }),
    getSeoInfoItem: build.query<ISeoInfoItem, ISeoInfoItemRequest>({
      query: (params) => requestItemOptions(params),
    }),
    addSeoInfo: build.mutation<ISeoInfoMutationResponse, ISeoInfoAddRequest>({
      query: (params) => ({
        ...requestMutateOptions(params),
        method: 'post',
      }),
    }),
    editSeoInfo: build.mutation<ISeoInfoMutationResponse, ISeoInfoEditRequest>({
      query: (params) => ({
        ...requestMutateOptions(params),
        method: 'put',
      }),
    }),
    deleteSeoInfo: build.mutation<ISeoInfoMutationResponse, ISeoInfoDeleteRequest>({
      query: (params) => ({
        ...requestMutateOptions(params),
        method: 'delete',
      }),
    }),
  }),
});

export const {
  useGetSeoInfoQuery: useGetSeoInfoList,
  useLazyGetSeoInfoQuery: useLazyGetSeoInfoList,
  useGetSeoInfoItemQuery: useGetSeoInfoItem,
  useAddSeoInfoMutation,
  useEditSeoInfoMutation,
  useDeleteSeoInfoMutation,
} = api;

export const getSeoInfoList = (): Promise<AxiosResponse<ISeoInfoListResponse>> => $api.request(requestListOptions());

export const getSeoInfoItem = (params: ISeoInfoItemRequest): Promise<AxiosResponse<ISeoInfoItem>> => {
  const { url, body } = requestItemOptions(params);
  return $api.request({ url, method: 'post', data: body });
};

export const addSeoInfo = (params: ISeoInfoAddRequest): Promise<AxiosResponse<number>> => {
  const { url, headers, body } = requestMutateOptions(params);
  return $api.request({ url, headers, method: 'post', data: body });
};

export const editSeoInfo = (params: ISeoInfoEditRequest): Promise<AxiosResponse<number>> => {
  const { url, headers, body } = requestMutateOptions(params);
  return $api.request({ url, headers, method: 'put', data: body });
};

export const deleteSeoInfo = (params: ISeoInfoDeleteRequest): Promise<AxiosResponse<number>> => {
  const { url, headers } = requestMutateOptions(params);
  return $api.request({ url, headers, method: 'delete' });
};
