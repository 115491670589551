import { $api, rtkApi } from '@op/api';
import { AxiosResponse } from 'axios';

import { API_PROMO_URL } from '../../Env';
import {
  IFaqTagAddRequest,
  IFaqTagDeleteRequest,
  IFaqTagEditRequest,
  IFaqTagListGetRequest,
  IFaqTagListGetResponse,
} from '../model/types/faqTag';

const requestListOptions = (params: IFaqTagListGetRequest) => ({
  url: `${API_PROMO_URL}/faq/tags`,
  headers: {
    Authorization: `Bearer ${params.token}`,
  },
});

const requestOptions = ({ token, ...params }: IFaqTagAddRequest | IFaqTagEditRequest | IFaqTagDeleteRequest) => ({
  url: `${API_PROMO_URL}/faq/tags/${params.id ?? ''}`,
  body: params,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const api = rtkApi.injectEndpoints({
  endpoints: (build) => ({
    getFaqTags: build.query<IFaqTagListGetResponse, IFaqTagListGetRequest>({
      query: requestListOptions,
    }),
    addFaqTag: build.mutation<number, IFaqTagAddRequest>({
      query: (params) => ({
        ...requestOptions(params),
        method: 'post',
      }),
    }),
    editFaqTag: build.mutation<number, IFaqTagEditRequest>({
      query: (params) => ({
        ...requestOptions(params),
        method: 'put',
      }),
    }),
    deleteFaqTag: build.mutation<number, IFaqTagDeleteRequest>({
      query: (params) => ({
        ...requestOptions(params),
        method: 'delete',
      }),
    }),
  }),
});

export const {
  useGetFaqTagsQuery: useGetFaqTagList,
  useLazyGetFaqTagsQuery: useLazyGetFaqTagList,
  useAddFaqTagMutation,
  useEditFaqTagMutation,
  useDeleteFaqTagMutation,
} = api;

export const getFaqTagList = (params: IFaqTagListGetRequest): Promise<AxiosResponse<IFaqTagListGetResponse>> =>
  $api.request(requestListOptions(params));

export const addFaqTag = (params: IFaqTagAddRequest): Promise<AxiosResponse<number>> => {
  const { url, headers, body } = requestOptions(params);
  return $api.request({ url, headers, method: 'post', data: body });
};

export const editFaqTag = (params: IFaqTagEditRequest): Promise<AxiosResponse<number>> => {
  const { url, headers, body } = requestOptions(params);
  return $api.request({ url, headers, method: 'put', data: body });
};

export const deleteFaqTag = (params: IFaqTagDeleteRequest): Promise<AxiosResponse<number>> => {
  const { url, headers } = requestOptions(params);
  return $api.request({ url, headers, method: 'delete' });
};
