import classNames from "classnames";
import React, { ReactElement, useState } from "react";

interface IProps {
  cells: ReactElement[];
  className: string;
  cardId: number;
  cardLink: string | number;
  isDeleted?: boolean;
  onDelete?: (id: number) => void;
  onRestore?: (id: number) => void;
  onEdit: (index: string | number) => void;
  onClick?: (index: string | number) => void;
}

const ItemCard = ({
  cells,
  className,
  cardId,
  cardLink,
  isDeleted,
  onDelete,
  onRestore,
  onClick,
  onEdit,
}: IProps) => {
  const [loading] = useState(false);

  const handleEdit = () => {
    onEdit(cardLink);
  };

  const handleClick = () => {
    if (!isDeleted && onClick) {
      onClick(cardLink);
    } else {
      handleEdit();
    }
  };

  return (
    <article
      className={classNames([
        "item-card",
        className,
        loading && "is-loading",
        isDeleted && "is-deleted",
      ])}
    >
      <button className="item-card__base-btn" onClick={handleClick} />
      {cells.map((cell, index) => (
        <div
          key={index}
          className={classNames(["item-card__cell", `cell-${index + 1}`])}
        >
          {cell}
        </div>
      ))}
      <div className="item-card__cell">
        <div className="item-card__btns">
          {onDelete && (
            <button
              className="btn-icon item-card__btn"
              onClick={() => onDelete(cardId)}
            >
              <span className="icon icon-close" />
            </button>
          )}
          {onRestore && (
            <button
              className="btn-icon item-card__btn"
              onClick={() => onRestore(cardId)}
            >
              <span className="icon icon-restore" />
            </button>
          )}
          {!isDeleted && (
            <button className="btn-icon item-card__btn" onClick={handleEdit}>
              <span className="icon icon-edit" />
            </button>
          )}
        </div>
      </div>
    </article>
  );
};

export default ItemCard;
