import AuthorStore from "@/app/providers/MobxStore/model/AuthorStore";
import ImageUploader from "@/components/ImageUploader";
import * as AuthorController from "@/controllers/author-controller";
import { ConfirmCloseModal, Modal } from "@/features/Modal";
import { requiredValidateWhiteSpaces } from "@/shared/lib/utils/requiredValidateWhiteSpaces";
import Input from "@/shared/ui/Form/ui/Input";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface IModalProps extends IModalOpen {
  data: Nullable<AuthorResponse>;
  onSave?: () => void;
}

export const defaultValues: Omit<AuthorResponse, "author_id"> = {
  // author_id: number;
  avatar: "",
  company: "",
  description: "",
  initials: "",
  name: "",
  user_id: null,
};

type TFormFields = Omit<AuthorResponse, "author_id">;

const AuthorModal = ({ open, onClose, data, onSave }: IModalProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: data || defaultValues,
    mode: "onSubmit",
  });

  const handleSave = async (formData: TFormFields) => {
    setLoading(true);
    const lastAuthorId = AuthorStore.data.reduce(
      (maxId, author) => (maxId = Math.max(author.author_id, maxId)),
      0
    ); // todo: tag id should come from server

    const response = data
      ? await AuthorController.editTag({
          ...formData,
          author_id: data.author_id,
        })
      : await AuthorController.addTag({
          ...formData,
          author_id: lastAuthorId + 1,
        });

    if (response) {
      onSave && onSave();
      onClose();
      reset(defaultValues);
    } else {
      // todo: show error
    }
    setLoading(false);
  };

  const handleClose = () => {
    if (Object.keys(dirtyFields).length > 0) {
      setConfirmModalOpen(true);
    } else {
      onClose();
    }
  };

  useEffect(() => {
    if (!open) {
      setConfirmModalOpen(false);
      reset(data || defaultValues);
    }
  }, [open, data, reset]);

  useEffect(() => {
    reset(data || defaultValues);
  }, [data, reset]);

  return (
    <Modal open={open} onClose={handleClose} className="profile-modal">
      <>
        <form
          className="profile-modal__holder"
          onSubmit={handleSubmit(handleSave)}
        >
          <div className="profile-modal__header">
            {data ? (
              <>
                <span className="profile-modal__header-title">
                  {t("AuthorEdit")}
                </span>
              </>
            ) : (
              <>
                <span className="profile-modal__header-title">
                  {t("AddAuthor")}
                </span>
                <p className="profile-modal__header-text">
                  {t("AuthorDescription")}
                </p>
              </>
            )}
          </div>
          <div className="form profile-modal__form">
            <div className="form__block">
              <span className="label">{t("Initials")}</span>
              <Input
                {...register("initials", {
                  ...requiredValidateWhiteSpaces(t("PasteInitials")),
                })}
                isInvalid={!!errors.initials}
                errorText={errors.initials?.message}
                maxLength={2}
              />
            </div>
            <div className="form__block">
              <span className="label">{t("Avatar")}</span>
              <div className="profile-modal__avatar">
                <Controller
                  control={control}
                  rules={{ required: t("LoadAvatar") }}
                  render={({ field: { onChange, value } }) => (
                    <ImageUploader
                      onChange={onChange}
                      value={value}
                      folder="authors"
                      isInvalid={!!errors.avatar}
                    />
                  )}
                  name="avatar"
                />
              </div>
            </div>
            <div className="form__block">
              <span className="label">{t("FirstName")}</span>
              <Input
                {...register("name", {
                  ...requiredValidateWhiteSpaces(t("PasteFirstName")),
                })}
                isInvalid={!!errors.name}
                errorText={errors.name?.message}
              />
            </div>
            <div className="form__block">
              <span className="label">{t("Company")}</span>
              <Input {...register("company")} />
            </div>

            <div className="form__block">
              <span className="label">{t("Description")}</span>
              <Input {...register("description")} />
            </div>
            <button className="btn btn-fond scale-up lighten-up btn-full blue">
              {data ? (
                <i className="btn__text">{loading ? t("Saving") : t("Save")}</i>
              ) : (
                <i className="btn__text">
                  {loading ? t("Saving") : t("AddAuthor")}
                </i>
              )}
            </button>
          </div>
        </form>
        <ConfirmCloseModal
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          onConfirm={onClose}
        />
      </>
    </Modal>
  );
};

export default AuthorModal;
