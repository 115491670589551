import AuthorStore from "@/app/providers/MobxStore/model/AuthorStore";
import ItemCard from "@/components/ItemCard";
import PaginationArrows from "@/components/PaginationArrows";
import * as AuthorController from "@/controllers/author-controller";
import { ConfirmModal } from "@/features/Modal";
import useModalConfirm from "@/shared/lib/hooks/useModalComfirm";
import useModalOpen from "@/shared/lib/hooks/useModalOpen";
import { pagination } from "@/shared/lib/utils/pagination";
import Input from "@/shared/ui/Form/ui/Input";
import { observer } from "mobx-react-lite";
import React, { ChangeEvent, useMemo } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import Author from "./Author";
import AuthorModal from "./AuthorModal";

const PAGINATION_SIZE = 18;

function Authors() {
  const { t } = useTranslation();
  const [modalEditorOpened, setModalEditorOpened] = useModalOpen();
  const [modalEditorData, setModalEditorData] =
    useState<Nullable<AuthorResponse>>(null);

  const [modalConfirm, setModalConfirm] = useModalConfirm();

  const [searchString, setSearchString] = useState("");
  const [currentPaginationPosition, setCurrentPaginationPosition] = useState(0);

  const loading = AuthorStore.loading;
  const authors = AuthorStore.data;

  const handlePagination = (next?: boolean) => {
    setCurrentPaginationPosition(
      pagination(
        currentPaginationPosition,
        PAGINATION_SIZE,
        authors.length,
        !!next
      )
    );
  };

  const handleConfirm = (id?: number) => {
    id
      ? setModalConfirm({ isOpen: true, id: id })
      : setModalConfirm({
          isOpen: false,
          id: null,
        });
  };

  const handleDelete = async () => {
    if (modalConfirm.id) {
      const response = await AuthorController.deleteAuthor(modalConfirm.id);
      if (response) {
        AuthorStore.deleteAuthor(modalConfirm.id);
        setModalConfirm({ isOpen: false, id: null });
      }
    }
  };

  const handleEdit = (id: AuthorResponse["author_id"]) => {
    const itemToEdit = authors.find((author) => author.author_id === id);
    if (itemToEdit) {
      setModalEditorData(itemToEdit);
      setModalEditorOpened(true);
    }
  };

  const handleAdd = () => {
    setModalEditorData(null);
    setModalEditorOpened(true);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value.toLowerCase());
    setCurrentPaginationPosition(0);
  };

  const filteredList = useMemo(() => {
    return authors.filter((author) =>
      author.name.toLowerCase().includes(searchString)
    );
  }, [authors, searchString]);

  const authorPost = (data: string) => {
    const dataToArray = data.split(", ");
    let postArray: Array<string> = [];
    if (dataToArray.length) {
      dataToArray.map((p, i) => i !== 0 && postArray.push(p));
    }
    const post = postArray.join(", ");
    return post;
  };

  const handleModalEditorOnSave = async () => {
    const { pageYOffset } = window;

    window.scrollTo(0, 0);
    await AuthorStore.fetchAuthors();
    setTimeout(() => {
      window.scrollTo(0, pageYOffset);
    }, 1);
  };

  return (
    <>
      <div className="profile__title-block">
        <strong className="profile__title">{t("AuthorsList")}</strong>
        <button
          className="btn btn-fond blue scale-up lighten-up size-s profile__title-btn"
          onClick={handleAdd}
        >
          <span className="icon icon-plus btn__icon" />
          <i className="btn__text">{t("AddAuthor")}</i>
        </button>
      </div>
      <div className="profile__control">
        <div className="profile__control-item">
          <div className="search-input profile__search">
            <Input
              placeholder={t("SearchByAuthors")}
              className="input_stroke profile__search-input"
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div className="profile__control-item">
          <div className="profile__control-block">
            <PaginationArrows
              onClick={handlePagination}
              currentPosition={currentPaginationPosition}
              paginationSize={PAGINATION_SIZE}
              catalogLength={filteredList.length}
            />
          </div>
        </div>
      </div>
      <div className="profile__body profile-authors">
        {loading && authors.length === 0 && <div>{t("Loading")}...</div>}
        {filteredList
          .slice(
            currentPaginationPosition,
            currentPaginationPosition + PAGINATION_SIZE
          )
          .map((author) => (
            <ItemCard
              key={author.author_id}
              className="card-author"
              cardId={author.author_id}
              cardLink={author.author_id}
              onDelete={handleConfirm}
              onEdit={() => handleEdit(author.author_id)}
              cells={[
                <Author
                  key={1}
                  name={author.name.split(", ").shift()}
                  initials={author.initials}
                  avatar={author.avatar}
                />,
                <React.Fragment key={2}>
                  {authorPost(author.name)}
                </React.Fragment>,
                <React.Fragment key={3}>{author.company}</React.Fragment>,
              ]}
            />
          ))}

        <div className="profile__pagination-bottom">
          <PaginationArrows
            onClick={handlePagination}
            currentPosition={currentPaginationPosition}
            paginationSize={PAGINATION_SIZE}
            catalogLength={authors.length}
          />
        </div>
      </div>

      <AuthorModal
        open={modalEditorOpened}
        onClose={() => setModalEditorOpened(false)}
        data={modalEditorData}
        onSave={handleModalEditorOnSave}
      />
      <ConfirmModal
        text={t("AuthorDeleteConfirm")}
        modalOpen={modalConfirm.isOpen}
        setModalOpen={handleConfirm}
        onConfirm={handleDelete}
      />
    </>
  );
}

// Disable static optimization and use server-side rendering bc of runtime dependency on env vars
export async function getServerSideProps() {
  return { props: {} };
}

export default observer(Authors);
