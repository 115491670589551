import { Modal } from "@/features/Modal";
import React from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  title?: string;
  text: string;
  modalOpen: boolean;
  setModalOpen: () => void;
  onConfirm: () => void;
}

export const ConfirmModal = ({
  title,
  modalOpen,
  setModalOpen,
  onConfirm,
  text,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      className="confirm-modal"
      canBeExpanded={false}
      open={modalOpen}
      onClose={setModalOpen}
    >
      <div className="confirm-modal__body">
        <strong className="confirm-modal__title">{title ?? t("Delete")}</strong>
        <p className="confirm-modal__text">{text}</p>
        <div className="confirm-modal__btns">
          <button
            className="btn btn-ghost ghost confirm-modal__btn"
            onClick={() => setModalOpen()}
          >
            <span className="btn__text">{t("Cancel")}</span>
          </button>
          <button
            className="btn btn-fond red confirm-modal__btn"
            onClick={onConfirm}
          >
            <span className="btn__text">{t("Confirm")}</span>
          </button>
        </div>
      </div>
    </Modal>
  );
};
