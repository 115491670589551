import { uiActions } from "@/features/UI";
import { useAppDispatch } from "@/shared/lib/hooks/useAppDispatch";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import UserStore from "../../app/providers/MobxStore/model/UserStore";
import { ROUTES } from "../../app/providers/Router/model/consts/routerConsts";
import Link from "../../shared/ui/Link";
import Author from "../Authors/Author";
import Dropdown from "../Dropdown";

function HeaderMenu() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { author: userAuthor, data: userData, isAuth } = UserStore;

  const handleJournalArticlesModalEditorOpen = useCallback(() => {
    dispatch(uiActions.setJournalArticlesModalEditorOpen(true));
  }, [dispatch]);

  const handleFaqArticlesModalEditorOpen = useCallback(() => {
    dispatch(uiActions.setFaqArticlesModalEditorOpen(true));
  }, [dispatch]);

  const handleLogout = () => {
    UserStore.logout();
    navigate(ROUTES.HOME.path);
  };

  const menuItems = useMemo(
    () => [
      {
        label: t("Journal") + " – " + t("ArticlesList"),
        onClick: handleJournalArticlesModalEditorOpen,
        link: ROUTES.JOURNAL.ARTICLES.path,
      },
      {
        label: t("Faq") + " – " + t("ArticlesList"),
        onClick: handleFaqArticlesModalEditorOpen,
        link: ROUTES.FAQ.ARTICLES.path,
      },
    ],
    [t, handleJournalArticlesModalEditorOpen, handleFaqArticlesModalEditorOpen]
  );

  if (!isAuth || !userData) return null;

  return (
    <Dropdown
      className="bottom-end"
      parentClassName="header__user"
      items={[
        <React.Fragment key={0}>
          <Author
            initials={userAuthor?.initials || userData?.username.slice(0, 2)}
            avatar={userAuthor?.avatar || ""}
            name={userAuthor?.name || userData?.full_name}
            className="dropdown__btn"
          />
        </React.Fragment>,
        ...menuItems.map((item) => (
          <React.Fragment key={item.link}>
            <div className="dropdown__btn">
              <Link to={item.link} className="header__user-dropdown-link">
                <span className="icon icon-publications dropdown__icon" />
                <em className="dropdown__text">{item.label}</em>
              </Link>
              <button
                className="btn-icon header__user-dropdown-plus"
                onClick={item.onClick}
              >
                <span className="icon icon-plus-black" />
              </button>
            </div>
          </React.Fragment>
        )),
        <React.Fragment key={2}>
          <button className="dropdown__btn" onClick={handleLogout}>
            {t("Exit")}
          </button>
        </React.Fragment>,
      ]}
    />
  );
}

export default observer(HeaderMenu);
