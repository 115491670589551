import { uiReducer } from "@/features/UI";
import { rtkApi } from "@op/api";
import {
  ReducersMapObject,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";

import { StateSchema } from "./StateSchema";

export function createReduxStore(initialState?: StateSchema) {
  const rootReducers: ReducersMapObject<StateSchema> = {
    ui: uiReducer,
    [rtkApi.reducerPath]: rtkApi.reducer,
  };

  const store = configureStore({
    reducer: combineReducers(rootReducers),
    devTools: process.env.NODE_ENV !== "production",
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(rtkApi.middleware),
  });

  return store;
}

export type AppDispatch = ReturnType<typeof createReduxStore>["dispatch"];
