import { UserStore } from "@/app/providers/MobxStore";
import ImageUploader from "@/components/ImageUploader";
import { ConfirmCloseModal, Modal } from "@/features/Modal";
import { SelectCategoryController } from "@/features/SelectCategoryController";
import { notify } from "@/shared/lib/utils/notify";
import { requiredValidateWhiteSpaces } from "@/shared/lib/utils/requiredValidateWhiteSpaces";
import { Input, Textarea } from "@/shared/ui/Form";
import {
  IReview,
  useAddReviewMutation,
  useEditReviewMutation,
} from "@op/entities";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

type TFormFields = Omit<IReview, "id" | "category"> & {};

export const defaultValues: TFormFields = {
  company: "",
  position: "",
  name: "",
  header: "",
  text: "",
  category_index: "",
  image: "",
};

interface IProps extends IModalOpen {
  data?: Nullable<IReview>;
  onSave?: () => void;
}

const ReviewsModal = ({ open, onClose, data, onSave }: IProps) => {
  const { t } = useTranslation();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [addReview, { isLoading: isAddReviewLoading }] = useAddReviewMutation();
  const [editReview, { isLoading: isEditReviewLoading }] =
    useEditReviewMutation();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: data || defaultValues,
    mode: "onSubmit",
  });

  const handleSave = async (formData: TFormFields) => {
    const requestData = {
      ...formData,
      token: UserStore.token!,
    };

    const response = data
      ? await editReview(requestData)
      : await addReview(requestData);

    if ("error" in response) {
      notify(t("AnErrorOcurred"));
      return;
    }

    if ("data" in response) {
      onSave?.();
      onClose();
      reset(defaultValues);
    }
  };

  const handleClose = () => {
    if (Object.keys(dirtyFields).length > 0) {
      setConfirmModalOpen(true);
    } else {
      onClose();
    }
  };

  useEffect(() => {
    if (!open) {
      setConfirmModalOpen(false);
      reset(data || defaultValues);
    }
  }, [open, data, reset]);

  useEffect(() => {
    reset(data || defaultValues);
  }, [data, reset]);

  return (
    <Modal open={open} onClose={handleClose} className="profile-modal">
      <>
        <form
          className="profile-modal__holder"
          onSubmit={handleSubmit(handleSave)}
        >
          <div className="profile-modal__header">
            {data ? (
              <span className="profile-modal__header-title">
                {t("ReviewsEdit")}
              </span>
            ) : (
              <>
                <span className="profile-modal__header-title">
                  {t("ReviewsAdd")}
                </span>
              </>
            )}
          </div>
          <div className="form profile-modal__form">
            <div className="form__block">
              <span className="label">{t("Category")}*</span>
              <SelectCategoryController
                name="category_index"
                control={control}
                errors={errors}
              />
            </div>
            <div className="form__block">
              <span className="label">{t("ReviewsHeader")}</span>
              <Input
                {...register("header")}
                isInvalid={!!errors.header}
                errorText={errors.header?.message}
              />
            </div>
            <div className="form__block">
              <span className="label">{t("ReviewsText")}*</span>
              <Textarea
                rows={5}
                {...register("text", {
                  ...requiredValidateWhiteSpaces(t("PasteReviewsText")),
                })}
                isInvalid={!!errors.text}
                errorText={errors.text?.message}
              />
            </div>
            <div className="form__block">
              <span className="label">{t("ReviewsTitle")}*</span>
              <Input
                {...register("name", {
                  ...requiredValidateWhiteSpaces(t("PasteReviewsTitle")),
                })}
                isInvalid={!!errors.name}
                errorText={errors.name?.message}
              />
            </div>
            <div className="form__block">
              <span className="label">{t("Position")}*</span>
              <Input
                {...register("position", {
                  ...requiredValidateWhiteSpaces(t("PastePosition")),
                })}
                isInvalid={!!errors.position}
                errorText={errors.position?.message}
              />
            </div>
            <div className="form__block">
              <span className="label">{t("Company")}*</span>
              <Input
                {...register("company", {
                  ...requiredValidateWhiteSpaces(t("PasteCompany")),
                })}
                isInvalid={!!errors.company}
                errorText={errors.company?.message}
              />
            </div>
            <div className="form__block">
              <span className="label">{t("CompanyLogo")}*</span>
              <div className="profile-modal__image">
                <Controller
                  control={control}
                  rules={{ required: t("LoadCompanyLogo") }}
                  render={({ field: { onChange, value } }) => (
                    <ImageUploader
                      onChange={onChange}
                      value={value}
                      folder="reviews"
                      isInvalid={!!errors.image}
                    />
                  )}
                  name="image"
                />
              </div>
              {errors.image && (
                <p className="input-error" style={{ display: "block" }}>
                  {errors.image?.message}
                </p>
              )}
            </div>
            <button
              className={classNames(
                "btn btn-fond scale-up lighten-up btn-full blue",
                Object.keys(errors).length > 0 && "btn-disabled"
              )}
              type="submit"
            >
              {data ? (
                <i className="btn__text">
                  {isEditReviewLoading || isAddReviewLoading
                    ? t("Saving")
                    : t("Save")}
                </i>
              ) : (
                <i className="btn__text">
                  {isAddReviewLoading || isEditReviewLoading
                    ? t("Saving")
                    : t("ReviewsAdd")}
                </i>
              )}
            </button>
          </div>
        </form>
        <ConfirmCloseModal
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          onConfirm={onClose}
        />
      </>
    </Modal>
  );
};

export default observer(ReviewsModal);
