import { $api, rtkApi } from '@op/api';
import { AxiosResponse } from 'axios';

import { API_PROMO_URL } from '../../Env';
import {
  IReview,
  IReviewAddRequest,
  IReviewDeleteRequest,
  IReviewEditRequest,
  IReviewGetRequest,
  IReviewListGetRequest,
  IReviewListGetResponse,
} from '../model/types/review';

const requestListOptions = (params: IReviewListGetRequest) => ({
  url: `${API_PROMO_URL}/reviews`,
});

const requestOptions = (params: IReviewGetRequest) => ({
  url: `${API_PROMO_URL}/reviews/${params.id ?? ''}`,
});

const mutationRequestOptions = ({ token, ...params }: IReviewAddRequest | IReviewEditRequest | IReviewDeleteRequest) => ({
  url: `${API_PROMO_URL}/reviews/${params.id ?? ''}`,
  body: params,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const api = rtkApi.injectEndpoints({
  endpoints: (build) => ({
    getReviews: build.query<IReviewListGetResponse, IReviewListGetRequest>({
      query: requestListOptions,
    }),
    addReview: build.mutation<number, IReviewAddRequest>({
      query: (params) => ({
        ...mutationRequestOptions(params),
        method: 'post',
      }),
    }),
    editReview: build.mutation<number, IReviewEditRequest>({
      query: (params) => ({
        ...mutationRequestOptions(params),
        method: 'put',
      }),
    }),
    deleteReview: build.mutation<number, IReviewDeleteRequest>({
      query: (params) => ({
        ...mutationRequestOptions(params),
        method: 'delete',
      }),
    }),
  }),
});

export const {
  useGetReviewsQuery: useGetReviewList,
  useLazyGetReviewsQuery: useLazyGetReviewList,
  useAddReviewMutation,
  useEditReviewMutation,
  useDeleteReviewMutation,
} = api;

export const getReviewList = (params: IReviewListGetRequest): Promise<AxiosResponse<IReviewListGetResponse>> =>
  $api.request(requestListOptions(params));

export const getReview = (params: IReviewGetRequest): Promise<AxiosResponse<IReview>> => $api.request(requestOptions(params));

export const addReview = (params: IReviewAddRequest): Promise<AxiosResponse<number>> => {
  const { url, headers, body } = mutationRequestOptions(params);
  return $api.request({ url, headers, method: 'post', data: body });
};

export const editReview = (params: IReviewEditRequest): Promise<AxiosResponse<number>> => {
  const { url, headers, body } = mutationRequestOptions(params);
  return $api.request({ url, headers, method: 'put', data: body });
};

export const deleteReview = (params: IReviewDeleteRequest): Promise<AxiosResponse<number>> => {
  const { url, headers } = mutationRequestOptions(params);
  return $api.request({ url, headers, method: 'delete' });
};
