import { UserStore } from "@/app/providers/MobxStore";
import ItemCard from "@/components/ItemCard";
import PaginationArrows from "@/components/PaginationArrows";
import { ConfirmModal } from "@/features/Modal";
import useModalConfirm from "@/shared/lib/hooks/useModalComfirm";
import useModalOpen from "@/shared/lib/hooks/useModalOpen";
import { notify } from "@/shared/lib/utils/notify";
import { pagination } from "@/shared/lib/utils/pagination";
import { Input } from "@/shared/ui/Form";
import {
  ISeoInfoItem,
  useDeleteSeoInfoMutation,
  useGetSeoInfoList,
} from "@op/entities";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { observer } from "mobx-react-lite";
import React, { ChangeEvent, useMemo } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import SeoInfoModal from "./SeoInfoModal";

const PAGINATION_SIZE = 18;

function SeoInfoList() {
  const { t } = useTranslation();
  const [modalEditorOpened, setModalEditorOpened] = useModalOpen();
  const [modalEditorData, setModalEditorData] =
    useState<Nullable<ISeoInfoItem>>(null);

  const [modalConfirm, setModalConfirm] = useModalConfirm();

  const [searchString, setSearchString] = useState("");
  const [currentPaginationPosition, setCurrentPaginationPosition] = useState(0);

  const {
    data: seoInfoList,
    isFetching: seoInfoListIsFetching,
    refetch: refetchSeoInfoList,
  } = useGetSeoInfoList();

  const [deleteSeoInfo] = useDeleteSeoInfoMutation();

  const handlePagination = (next?: boolean) => {
    setCurrentPaginationPosition(
      pagination(
        currentPaginationPosition,
        PAGINATION_SIZE,
        seoInfoList?.length ?? 0,
        !!next
      )
    );
  };

  const handleConfirm = (id?: number) => {
    id
      ? setModalConfirm({ isOpen: true, id: id })
      : setModalConfirm({
          isOpen: false,
          id: null,
        });
  };

  const handleDelete = async () => {
    if (modalConfirm.id) {
      const response = await deleteSeoInfo({
        id: modalConfirm.id,
        token: UserStore.token ?? "",
      });
      if ("error" in response) {
        console.error(response.error);
        notify((response.error as FetchBaseQueryError).data);
        return;
      }

      if ("data" in response) {
        setModalConfirm({ isOpen: false, id: null });
        refetchSeoInfoList();
      }
    }
  };

  const handleEdit = (id: ISeoInfoItem["id"]) => {
    const itemToEdit = seoInfoList?.find((seoInfo) => seoInfo.id === id);
    if (itemToEdit) {
      setModalEditorData(itemToEdit);
      setModalEditorOpened(true);
    }
  };

  const handleAdd = () => {
    setModalEditorData(null);
    setModalEditorOpened(true);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value.toLowerCase());
    setCurrentPaginationPosition(0);
  };

  const filteredList = useMemo(() => {
    return (
      seoInfoList?.filter(
        (seoInfo) =>
          seoInfo.title.toLowerCase().includes(searchString) ||
          seoInfo.index.toLowerCase().includes(searchString)
      ) ?? []
    );
  }, [seoInfoList, searchString]);

  const handleModalEditorOnSave = async () => {
    const { pageYOffset } = window;

    window.scrollTo(0, 0);
    refetchSeoInfoList();
    setTimeout(() => {
      window.scrollTo(0, pageYOffset);
    }, 1);
  };

  return (
    <>
      <div className="profile__title-block">
        <strong className="profile__title">{t("SeoInfoList")}</strong>
        <button
          className="btn btn-fond blue scale-up lighten-up size-s profile__title-btn"
          onClick={handleAdd}
        >
          <span className="icon icon-plus btn__icon" />
          <i className="btn__text">{t("AddSeoInfo")}</i>
        </button>
      </div>
      <div className="profile__control">
        <div className="profile__control-item">
          <div className="search-input profile__search">
            <Input
              placeholder={t("SearchBySeoInfoTitle")}
              className="input_stroke profile__search-input"
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div className="profile__control-item">
          <div className="profile__control-block">
            <PaginationArrows
              onClick={handlePagination}
              currentPosition={currentPaginationPosition}
              paginationSize={PAGINATION_SIZE}
              catalogLength={filteredList.length}
            />
          </div>
        </div>
      </div>
      <div className="profile__body profile-seo-info-list">
        {seoInfoListIsFetching && filteredList.length === 0 && (
          <div>{t("Loading")}...</div>
        )}
        {filteredList
          .slice(
            currentPaginationPosition,
            currentPaginationPosition + PAGINATION_SIZE
          )
          .map((seoInfo) => (
            <ItemCard
              key={seoInfo.id}
              className="card-seo-info"
              cardId={seoInfo.id}
              cardLink={seoInfo.id}
              onDelete={handleConfirm}
              onEdit={() => handleEdit(seoInfo.id)}
              cells={[
                <React.Fragment key={1}>{seoInfo.index}</React.Fragment>,
                <React.Fragment key={2}>
                  <strong>{seoInfo.title}</strong>
                  {seoInfo.header}
                </React.Fragment>,
              ]}
            />
          ))}

        <div className="profile__pagination-bottom">
          <PaginationArrows
            onClick={handlePagination}
            currentPosition={currentPaginationPosition}
            paginationSize={PAGINATION_SIZE}
            catalogLength={seoInfoList?.length ?? 0}
          />
        </div>
      </div>
      <SeoInfoModal
        open={modalEditorOpened}
        onClose={() => setModalEditorOpened(false)}
        data={modalEditorData}
        onSave={handleModalEditorOnSave}
      />
      <ConfirmModal
        text={t("SeoInfoDeleteConfirm")}
        modalOpen={modalConfirm.isOpen}
        setModalOpen={handleConfirm}
        onConfirm={handleDelete}
      />
    </>
  );
}

export default observer(SeoInfoList);
