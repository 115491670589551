import userStore from "@/app/providers/MobxStore/model/UserStore";
import ItemCard from "@/components/ItemCard";
import PaginationArrows from "@/components/PaginationArrows";
import { ConfirmModal } from "@/features/Modal";
import useModalConfirm from "@/shared/lib/hooks/useModalComfirm";
import useModalOpen from "@/shared/lib/hooks/useModalOpen";
import { notify } from "@/shared/lib/utils/notify";
import { pagination } from "@/shared/lib/utils/pagination";
import Input from "@/shared/ui/Form/ui/Input";
import { ReviewsModal } from "@/widgets/ReviewsModal";
import {
  IReview,
  useDeleteReviewMutation,
  useGetReviewList,
} from "@op/entities";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { observer } from "mobx-react-lite";
import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";

const PAGINATION_SIZE = 18;

const MAX_TEXT_LENGTH = 120;

function Reviews() {
  const { t } = useTranslation();
  const [modalEditorOpened, setModalEditorOpened] = useModalOpen();
  const [modalEditorData, setModalEditorData] = useState<IReview>();
  const [modalConfirm, setModalConfirm] = useModalConfirm();

  const [searchString, setSearchString] = useState("");
  const [currentPaginationPosition, setCurrentPaginationPosition] = useState(0);

  const {
    data: reviewsList,
    isFetching: reviewsListIsFetching,
    refetch: refetchReviewsList,
  } = useGetReviewList({});

  const [deleteReview] = useDeleteReviewMutation();

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value.toLowerCase());
    setCurrentPaginationPosition(0);
  };

  const handlePagination = (next?: boolean) => {
    setCurrentPaginationPosition(
      pagination(
        currentPaginationPosition,
        PAGINATION_SIZE,
        reviewsList?.length ?? 0,
        !!next
      )
    );
  };

  const handleConfirm = (id?: number) => {
    id
      ? setModalConfirm({ isOpen: true, id: id })
      : setModalConfirm({
          isOpen: false,
          id: null,
        });
  };

  const handleDelete = async () => {
    if (modalConfirm.id) {
      const response = await deleteReview({
        id: modalConfirm.id,
        token: userStore.token ?? "",
      });
      if ("error" in response) {
        console.error(response.error);
        notify((response.error as FetchBaseQueryError).data);
        return;
      }

      if ("data" in response) {
        setModalConfirm({ isOpen: false, id: null });
        refetchReviewsList();
      }
    }
  };

  const handleEdit = (data: IReview) => {
    if (data) {
      setModalEditorOpened(true);
      setModalEditorData(data);
    }
  };

  const handleAdd = () => {
    setModalEditorOpened(true);
    setModalEditorData(undefined);
  };

  const handleModalEditorOnSave = async () => {
    const { pageYOffset } = window;

    window.scrollTo(0, 0);
    await refetchReviewsList();
    setTimeout(() => {
      window.scrollTo(0, pageYOffset);
    }, 1);
  };

  const filteredItems = reviewsList?.filter(
    (item) =>
      item.text.toLowerCase().includes(searchString) ||
      item.header.toLowerCase().includes(searchString)
  );

  if (reviewsListIsFetching) {
    return (
      <>
        <div className="profile__title-block">
          <strong className="profile__title">{t("ReviewsList")}</strong>
        </div>
        <div className="profile__body profile-rubrics">
          <div>{t("Loading")}...</div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="profile__title-block">
        <strong className="profile__title">{t("ReviewsList")}</strong>
        <button
          className="btn btn-fond blue scale-up lighten-up size-s profile__title-btn"
          onClick={handleAdd}
        >
          <span className="icon icon-plus btn__icon" />
          <i className="btn__text">{t("ReviewsAdd")}</i>
        </button>
      </div>
      <div className="profile__control">
        <div className="profile__control-item">
          <div className="search-input profile__search">
            <Input
              placeholder={t("SearchByReviews")}
              className="input_stroke profile__search-input"
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div className="profile__control-item">
          <div className="profile__control-block">
            <PaginationArrows
              onClick={handlePagination}
              currentPosition={currentPaginationPosition}
              paginationSize={PAGINATION_SIZE}
              catalogLength={reviewsList?.length ?? 0}
            />
          </div>
        </div>
      </div>
      <div className="profile__body profile-rubrics">
        {filteredItems
          ?.slice(
            currentPaginationPosition,
            currentPaginationPosition + PAGINATION_SIZE
          )
          .map((item) => (
            <ItemCard
              key={item.id}
              className="card-review"
              cardId={item.id}
              cardLink={item.id}
              onDelete={handleConfirm}
              onEdit={() => handleEdit(item)}
              cells={[
                <React.Fragment key={1}>
                  <p>{item.name}</p>
                  <span>{item.company}</span>
                </React.Fragment>,
                <React.Fragment key={2}>
                  <strong
                    dangerouslySetInnerHTML={{
                      __html: item.header,
                    }}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        item.text.length > MAX_TEXT_LENGTH
                          ? item.text.slice(0, MAX_TEXT_LENGTH) + "..."
                          : item.text,
                    }}
                  />
                </React.Fragment>,
                <React.Fragment key={3}>
                  <p>{item.category}</p>
                </React.Fragment>,
              ]}
            />
          ))}

        <div className="profile__pagination-bottom">
          <PaginationArrows
            onClick={handlePagination}
            currentPosition={currentPaginationPosition}
            paginationSize={PAGINATION_SIZE}
            catalogLength={reviewsList?.length ?? 0}
          />
        </div>
      </div>

      <ReviewsModal
        open={modalEditorOpened}
        onClose={() => setModalEditorOpened(false)}
        data={modalEditorData}
        onSave={handleModalEditorOnSave}
      />
      <ConfirmModal
        text={t("ReviewsDeleteConfirm")}
        modalOpen={modalConfirm.isOpen}
        setModalOpen={handleConfirm}
        onConfirm={handleDelete}
      />
    </>
  );
}

export default observer(Reviews);
